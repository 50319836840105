// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0Sf9Sas1iSZHn3bR35fj0pGbCoPkTTwg",
  authDomain: "tweet-recommender.firebaseapp.com",
  projectId: "tweet-recommender",
  storageBucket: "tweet-recommender.appspot.com",
  messagingSenderId: "427195139299",
  appId: "1:427195139299:web:561f253401d24cfe6571d6",
  measurementId: "G-XPYYX9NQ6E",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
