import React, { useEffect, useState } from "react";
import { Tweet } from "react-twitter-widgets";
import * as _ from "lodash";
import Modal from "react-modal";
// import { useAnimation, motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";

type TweetResultEntry = {
  search: {
    query: string;
    start_char: number;
    end_char: number;
  };
  result?: {
    title: string;
    subtitle?: string;
    img?: string;
    link: string;
    isbn: string;
    rawResult: string;
    authors: string[];
    type: "book" | "movie" | "game";
    links?: Record<string, string>;
  };
};

type TweetUrlEntry = {
  expanded_url: string;
  title?: string;
  domain?: string;
};

type TweetReplyEntry = {
  //   tweet: any;
  id: string;
  text: string;
  searches: string[];
  results: TweetResultEntry[];
  urls: TweetUrlEntry[];
};

type MiniTweet = {
  author_id: string;
  text: string;
  author_id_hydrate: {
    name: string;
    username: string;
  };
};

export type TweetThreadData = {
  tweet_id: string;
  conversation_id: string;
  ts: number;
  tweet_ids: string[];
  tweets: TweetReplyEntry[];
  original_tweet?: MiniTweet;
};

Modal.setAppElement("#root");

const GroupedTweetResultModalBody = function (props: {
  group: TweetResultPair[];
  img: string;
}) {
  const { group, img } = props;
  const tweetIds = group.map((t) => t.tweetId);

  const {
    result: { result },
  } = group[0];

  if (!result) {
    return null;
  }

  const { isbn, type } = group[0].result.result || {};

  const Links = () => {
    if (result.links) {
      Object.entries(result.links).map(([site, link]) => {
        return (
          <a href={link} className="underline cursor-pointer" key={link}>
            View on {site}
          </a>
        );
      });
    }

    return (
      <>
        {type === "book" && (
          <>
            <a
              href={`https://bookshop.org/books?keywords=${isbn}`}
              className="underline cursor-pointer"
            >
              Search on bookshop.org
            </a>
            <br />
            <a href={result.link} className="underline cursor-pointer">
              View on Google Books
            </a>
          </>
        )}

        {type === "game" && (
          <>
            <a href={result.link} className="underline cursor-pointer">
              View on IGDB
            </a>
          </>
        )}

        {type === "movie" && (
          <>
            <a href={result.link} className="underline cursor-pointer">
              View on IMDB
            </a>
          </>
        )}
      </>
    );
  };

  return (
    <div className="h-full flex flex-col">
      <div className="pb-6">
        <div className="flex flex-row ">
          {result.img && (
            <img
              style={{
                objectFit: "contain",
                objectPosition: "top",
              }}
              className="w-[35vw] lg:w-[300px]"
              src={img}
              alt={result.title}
            />
          )}
          <div className="flex flex-col pl-6 shrink">
            <h1 className="text-2xl ">{result.title}</h1>
            {type === "book" && (
              <>
                <br />
                <h2 className="text-xl ">by {result.authors[0]}</h2>
              </>
            )}

            {(!isMobile || type !== "book") && (
              <>
                <br />
                <Links />
              </>
            )}
          </div>
        </div>
        {isMobile && type === "book" && (
          <>
            <br />
            <div className="text-center">
              <Links />
            </div>
          </>
        )}
      </div>

      <div className="w-full flex flex-col align-start content-start justify-start grow">
        {_.uniq(tweetIds).map((tweetId) => (
          <Tweet
            tweetId={tweetId}
            options={{
              conversation: "none",
              cards: "hidden",
            }}
            key={tweetId}
          />
        ))}
      </div>
    </div>
  );
};

const GroupedTweetResult = function (props: { group: TweetResultPair[] }) {
  const { group } = props;
  const {
    result: { result },
  } = group[0];

  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const size = useWindowSize();

  if (!result || !result.img) {
    return null;
  }
  // https://books.google.com/books/content?id=ZSmZDwAAQBAJ&printsec=frontcover&img=1&zoom=1&edge=curl&source=gbs_api
  // https://books.google.com/books/publisher/content/images/frontcover/ZSmZDwAAQBAJ?fife=w800-h600
  let img = result.img;
  if (img.includes("books.google.com/books/content")) {
    const id = new URL(img).searchParams.get("id");
    if (id) {
      img = `https://books.google.com/books/publisher/content/images/frontcover/${id}?fife=w800-h600`;
    }
  }

  return (
    <div className="relative grow  m-4 flex align-middle items-center justify-center">
      <span className="relative inline-block text-gray-700 fill-current">
        {/* <a href={result.link}><div>{result.title}</div></a> */}
        <div
          className="relative cursor-pointer"
          onClick={() => setShowModal(true)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {result.img && (
            <img
              className="w-[40vw] md:w-[200px] md:min md:min-h-[200px] object-contain"
              src={img}
              alt={result.title}
            />
          )}
          {isHovered && (
            <div
              className="border-8 border-orange-300 p-6 flex flex-col"
              style={{
                display: "relative",
                position: "absolute",
                top: 0,
                left: 0,
                background: "rgba(255,255,255, 0.8)",
                width: "100%",
                height: "100%",
                animation: "fadeIn linear 7s",
              }}
            >
              <div className="text-xl font-bold	">{result.title}</div>
              {result.type === "book" && (
                <div className="text-xl">{result.authors?.[0]}</div>
              )}

              <div className="text-xl font-bold absolute p-6 text-center underline bottom-0 left-0 cursor-pointer">
                See tweets and details
              </div>
            </div>
          )}

          {/* Details */}
        </div>
        {group.length > 1 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
            {group.length}
          </span>
        )}
      </span>

      {showModal && (
        <Modal
          onRequestClose={() => setShowModal(false)}
          isOpen={showModal}
          preventScroll={true}
          style={{
            content: isMobile
              ? {
                  padding: 10,
                  inset: 10,
                  // backgroundColor: "#d3d3d3",
                }
              : {
                  // top, right, bottom and left offsets
                  inset: `40px ${(size.width! - 500) / 2}px`,
                },
          }}
        >
          <GroupedTweetResultModalBody group={group} img={img} />
        </Modal>
      )}
    </div>
  );
};

type TweetResultPair = {
  tweetId: string;
  result: TweetResultEntry;
};

export default function TweetThread(props: { data: TweetThreadData }) {
  const { data } = props;

  const tweetResultPairs: TweetResultPair[] = data.tweets.flatMap((tweet) =>
    tweet.results.map((result) => ({ tweetId: tweet.id, result }))
  );

  const groupedTweetResultPairs = _.groupBy(
    tweetResultPairs.filter((trp) => trp.result.result),
    (trp) => trp.result.result!.isbn
  );

  const sortedGroups = Object.values(groupedTweetResultPairs).sort(
    (a, b) => b.length - a.length
  );

  const allUrls = _.uniq(
    data.tweets
      .flatMap((tweet) => {
        return tweet.urls;
      })
      .filter((u) => !u.expanded_url.includes("twitter.com"))
  );
  allUrls.forEach((u) => (u.domain = new URL(u.expanded_url).host));

  return (
    <>
      {data.original_tweet && (
        <Helmet>
          <title>{`twitrecos: @${data.original_tweet.author_id_hydrate.username} - ${data.original_tweet.text}`}</title>
          <meta
            name="description"
            content={`twitrecos: @${data.original_tweet.author_id_hydrate.username} - ${data.original_tweet.text}`}
          />
        </Helmet>
      )}
      <div className="flex flex-col align-middle justify-center items-center">
        <div className="max-w-[94vw] w-[94vw] lg:max-w-[600px]">
          <Tweet tweetId={data.conversation_id} options={{ cards: "hidden" }} />
        </div>
        <div className="flex flex-wrap w-screen max-w-screen-lg">
          {sortedGroups.map((group) => (
            <GroupedTweetResult
              group={group}
              key={group[0].result.result!.isbn}
            />
          ))}
        </div>
      </div>

      {allUrls.length > 0 && (
        <div>
          <h2 className="text-2xl">All mentioned urls</h2>
          <ul className="list-disc">
            {allUrls.map((u) => {
              const { expanded_url: url, title } = u;
              return (
                <li key={url}>
                  <a
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-60"
                    href={url}
                  >
                    {title || url}
                  </a>{" "}
                  - {u.domain}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
