import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MyProps {}

export function Container(props: React.PropsWithChildren<MyProps>) {
  return (
    <div className="m-auto flex flex-col align-middle justify-center align-cen items-center">
      {props.children}
    </div>
  );
}
