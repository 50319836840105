import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { Tweet } from "react-twitter-widgets";
import { firebaseApp } from "./firebaseApp";
import TweetThread, { TweetThreadData } from "./TweetThread";
import { Container } from "./Container";

const NotIndexed = function (props: { tweetId: string }) {
  const { tweetId } = props;

  const [didEnqueue, setDidEnqueue] = useState(false);

  const enqueueTweet = async () => {
    setDidEnqueue(true);
    const db = getFirestore();
    await setDoc(doc(db, "queue", tweetId), {
      tweetId,
      processed: false,
    });
  };

  const [value, loading, _error] = useDocument(
    doc(getFirestore(firebaseApp), "queue", tweetId!),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const data = value?.data();
  console.log(data);

  return (
    <Container>
      {loading && <div>Checking Queue</div>}
      {data && !didEnqueue && <div>Already enqueued, check back later</div>}
      {didEnqueue && <div>Processing now ... check back later</div>}

      {!data && !didEnqueue && !loading && (
        <>
          <Tweet tweetId={tweetId} options={{ cards: "hidden" }} />

          <div className="my-4 mt-8">
            This tweet is not indexed yet, would you like to enqueue it now for
            processing?
          </div>
          {!didEnqueue && (
            <button
              onClick={enqueueTweet}
              className="w-fit bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Enqueue Now
            </button>
          )}
        </>
      )}
    </Container>
  );
};

export default function TweetThreadLoader() {
  const params = useParams();
  const { tweetId } = params;

  const [value, loading, error] = useDocument(
    doc(getFirestore(firebaseApp), "tweet_to_replies", tweetId!),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const data = value?.data();

  if (!tweetId) {
    return <h1>need a tweetId</h1>;
  }

  return (
    <div className="content-center items-center flex flex-col h-screen">
      {error && (
        <strong>
          Error:
          {JSON.stringify(error)}
        </strong>
      )}
      {loading && <Container>Checking ...</Container>}
      {data && <TweetThread data={data as unknown as TweetThreadData} />}
      {value && !data && <NotIndexed tweetId={tweetId} />}
    </div>
  );
}
