import {
  collection,
  getFirestore,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import _ from "lodash";
import React from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { Tweet } from "react-twitter-widgets";
import { Container } from "./Container";
import { firebaseApp } from "./firebaseApp";
import { TweetThreadData } from "./TweetThread";

function RenderDoc(props: { data: TweetThreadData }) {
  const { data } = props;

  const isbns = data.tweets
    .flatMap((tweet) => tweet.results.flatMap((r) => r.result?.isbn))
    .filter(_.isString);

  if (_.uniq(isbns).length === 0) {
    return null;
  }

  return (
    <a href={`/${data.conversation_id}`}>
      <div className="border-2 p-2 m-2 max-w-screen-m pointer-events-none	">
        <Tweet tweetId={data.conversation_id} />
        <div>{_.uniq(isbns).length} Items</div>
        <div>
          Indexed on {new Date(data.ts * 1000).toLocaleDateString()} at{" "}
          {new Date(data.ts * 1000).toLocaleTimeString()}
        </div>
      </div>
    </a>
  );
}

export default function IndexPage() {
  const [value, loading, _error] = useCollectionOnce(
    query(
      collection(getFirestore(firebaseApp), "tweet_to_replies"),
      orderBy("ts", "desc"),
      limit(100)
    )
  );

  const docs = value?.docs.filter((d) => d.data()["tweets"].length > 0);

  return (
    <Container>
      <div className="content-center items-center flex flex-col h-screen">
        {loading && <div className="text-3xl">Loading...</div>}
        {!loading && !docs && <div className="text-3xl">I got bupkis</div>}
        {docs &&
          docs.map((doc) => {
            return (
              <div key={doc.id}>
                <RenderDoc data={doc.data() as unknown as TweetThreadData} />
              </div>
            );
          })}
      </div>
    </Container>
  );
}
